@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');

*,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  background-color: #eae9e9;
}

.container {
  margin: 20px;
  border-radius: 20px;
  background-color: #f6f6f6;
  padding: 10px;
  height: 90vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container h1 {
  font-size: 120%;
  text-align: center;
  margin: 10px 0;
}

.container h2 {
  font-size: 100%;
  margin-top: 20px;
}

.stats__container {
  display: flex;
  justify-content: space-between;
}

.stats__container p {
  text-transform: uppercase;
}

.box {
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.box p {
  font-size: 70%;
  margin: 5px 0;
}

.confirmed p {
  background-color: #f7be16;
}

.recovered p {
  background-color: #36622b;
  color: #f6f6f6;
}

.recovered h3 {
  color: #36622b;
}

.deaths p {
  background-color: #db2d43;
  color: #f6f6f6;
}

.deaths h3 {
  color: #db2d43;
}

/* country info */
.country__showing {
  border: 1px solid #ccc;
  border-radius: 15px;
  /* margin-bo */
  padding: 10px;
}

.country {
  display: flex;
  margin: 10px;
}

.country__info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.country__info img {
  border-radius: 50%;
  margin-right: 5px;
}

.country__select {
  width: 40%;
  padding: 5px;
  border-radius: 10px;
  background-color: transparent;
}

.country__select:focus {
  outline: none;
}

footer p {
  text-align: center;
  font-size: 60%;
}
